<template>
  <div class="home-page">
    <header ref="headerItem">
      <section>
        <router-link to="/logout" class="icon-wrapper">
          <img src="@/assets/images/logout-icon.png" />
          <span>Logout</span>
        </router-link>
      </section>
      <section>
        <span>SharkTrac Nodes</span>
      </section>
      <section>
        <router-link to="/settings" class="icon-wrapper turnable">
          <img src="@/assets/images/settings-icon.jpeg" />
          <span>Settings</span>
        </router-link>
      </section>
    </header>
    <main>
      <div class="map-wrapper">
        <GMapMap
          :center="center"
          :zoom="2"
          map-type-id="terrain"
          class="map-outer"
        >
          <GMapCluster>
            <GMapMarker
              :key="index"
              v-for="(m, index) in markers"
              :position="m.position"
              :clickable="true"
              :draggable="true"
              @click="center = m.position && handleClick(m.MacId)"
            />
          </GMapCluster>
        </GMapMap>
      </div>
    </main>
    <Footer ref="footerItem" />
  </div>
</template>

<style lang="scss">
.home-page {
  background-image: url("@/assets/images/login-background.jpeg");
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100vh;
  width: 100%;
  background-position: center center;
  background-size: cover;

  main {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    padding: 15px 50px;

    .map-wrapper {
      width: 100%;
      border-radius: 5px;
      background: linear-gradient(
        90deg,
        rgba(250, 209, 38, 1) 8%,
        rgba(253, 139, 73, 1) 63%,
        rgba(254, 75, 70, 1) 100%
      );
      padding: 5px;
      height: 100%;
      position: relative;

      .map-outer {
        width: 100%;
        height: 60vh;
        border-radius: 10px;
        overflow: hidden;
      }
    }
  }

  header {
    display: grid;
    grid-template-columns: 200px 3fr 200px;
    align-content: center;
    align-items: center;
    width: 100%;
    padding: 20px 50px;
    background-color: rgba(#222, 0.6);
    border-bottom: 1px solid #222;

    section:nth-of-type(1) {
      justify-self: start;

      .icon-wrapper {
        display: flex;
        text-decoration: none;
        color: #cecece;
        justify-content: center;
        align-items: center;

        img {
          display: block;
          padding: 10px;
          width: 50px;
          height: 50px;
        }
      }
    }

    section:nth-of-type(2) {
      justify-self: center;

      span {
        color: #cecece;
        font-size: 36px;
        font-family: "Arial", sans-serif;
      }
    }

    section:nth-of-type(3) {
      justify-self: end;

      .icon-wrapper {
        display: flex;
        text-decoration: none;
        color: #cecece;
        justify-content: center;
        align-items: center;

        img {
          display: block;
          padding: 10px;
          width: 50px;
          height: 50px;
        }
      }
    }
  }
}
</style>

<script>
import Footer from "@/components/Footer";
import ApiService from "@/api/main";

export default {
  name: "NodeMap",
  components: {
    Footer,
  },
  data() {
    return {
      center: { lat: 51.093048, lng: 6.84212 },
      markers: [
        /*
        {
          position: {
            lat: 51.093048,
            lng: 6.84212,
          },
        }, // Along list of clusters
        */
      ],
    };
  },
  methods: {
    async handleClick(nodeId) {
      const nodeData = await ApiService.getNode(nodeId);

      if (!nodeData) {
        console.log("Non-existent node is clicked.");
        return;
      }

      if (nodeData.status == "error") {
        console.log("Error while retrieving node data.");
        return;
      }

      if (nodeData.data.Streaming != 1) {
        this.$swal("This node is offline!");
        return;
      }

      console.log(nodeData.data);
      this.$router.push("/liveNode/" + nodeId);
    },
  },
  beforeMount() {
    const token = localStorage.apiToken;
    if (!token) {
      this.$router.push("/login");
    }

    ApiService.getNodes()
      .then((data) => {
        console.log(data.data);
        let nodes = data.data;
        for (let node of nodes) {
          this.markers.push({
            id: node.Id,
            MacId: node.MacId,
            position: {
              lat: parseInt(node.Latitude),
              lng: parseInt(node.Longitude),
            },
          });

          this.center = {
            lat: parseInt(node.Latitude),
            lng: parseInt(node.Longitude),
          };
        }
      })
      .catch((err) => console.log(err));
  },
};
</script>
