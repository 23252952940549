import { createRouter, createWebHashHistory } from "vue-router";
import Home from "../views/Home.vue";

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/login",
    name: "Login",
    component: () =>
      import(/* webpackChunkName: "login" */ "../views/LoginPage.vue"),
  },
  {
    path: "/settings",
    name: "Settings",
    component: () =>
      import(/* webpackChunkName: "settings" */ "../views/SettingsPage.vue"),
  },
  {
    path: "/newNode",
    name: "NewNode",
    component: () =>
      import(/* webpackChunkName: "newNode" */ "../views/NewNodePage.vue"),
  },
  {
    path: "/liveNode/:nodeId",
    name: "LiveNode",
    component: () =>
      import(/* webpackChunkName: "liveNode" */ "../views/LiveNodePage.vue"),
  },
  {
    path: "/logout",
    name: "Logout",
    beforeEnter: (to, from, next) => {
      localStorage.removeItem("apiToken");
      next({ name: "Login" });
    },
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});

export default router;
