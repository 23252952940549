import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import VueGoogleMaps from "@fawmi/vue-google-maps";
import VueSweetalert2 from "vue-sweetalert2";
import "sweetalert2/dist/sweetalert2.min.css";
import LoadScript from "vue-plugin-load-script";

createApp(App)
  .use(VueGoogleMaps, {
    load: {
      key: "AIzaSyAgX_nOQygYzrXT_qJVuBHQzosGJzveI_I",
    },
  })
  .use(router)
  .use(VueSweetalert2)
  .use(LoadScript)
  .mount("#app");
