<template>
  <router-view />
</template>

<style lang="scss">
* {
  border: 0;
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  list-style: none;
}

#app {
  font-family: Verdana, Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

.icon-wrapper {
  img {
    display: block;
    padding: 10px;
    width: 60px;
    height: 60px;
  }

  &.turnable:hover img {
    -webkit-animation-name: rotate;
    -webkit-animation-duration: 1s;
    -webkit-animation-iteration-count: 1;
    -webkit-animation-timing-function: linear;

    animation-name: rotate;
    animation-duration: 1s;
    animation-iteration-count: 1;
    animation-timing-function: linear;
  }
}

@-webkit-keyframes rotate {
  from {
    -webkit-transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
</style>
