const axios = require("axios");
const baseURI = "https://sharktrac.live/api";

const login = async (username, password) => {
  try {
    let apiRequest = await axios.post(baseURI + "/user/login", {
      username: username,
      password: password,
    });

    const result = apiRequest.data;

    return result;
  } catch (error) {
    console.log(error);
    return false;
  }
};

const getNodes = async () => {
  const token = localStorage.apiToken;
  if (!token) {
    console.log("Not authenticated");
    return false;
  }

  try {
    let apiRequest = await axios.get(baseURI + "/devices", {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    const result = apiRequest.data;

    return result;
  } catch (error) {
    console.log(error);
    return false;
  }
};

const getNode = async (nodeId) => {
  const token = localStorage.apiToken;
  if (!token) {
    console.log("Not authenticated");
    return false;
  }

  try {
    let apiRequest = await axios.get(baseURI + "/device/" + nodeId, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    const result = apiRequest.data;

    return result;
  } catch (error) {
    console.log(error);
    return false;
  }
};

const changeLedColor = async (nodeId, color) => {
  const token = localStorage.apiToken;
  if (!token) {
    console.log("Not authenticated");
    return false;
  }

  if (color.length !== 6) {
    console.log("Invalid color");
    return false;
  }

  try {
    let apiRequest = await axios.get(
      baseURI + "/ledColor/" + nodeId + "/" + color,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    const result = apiRequest.data;

    return result;
  } catch (error) {
    console.log(error);
    return false;
  }
};

const startVideoFeed = async (nodeId) => {
  const token = localStorage.apiToken;
  if (!token) {
    console.log("Not authenticated");
    return false;
  }

  try {
    let apiRequest = await axios.get(baseURI + "/startVideoFeed/" + nodeId, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    const result = apiRequest.data;

    return result;
  } catch (error) {
    console.log(error);
    return false;
  }
};

const stopVideoFeed = async (nodeId) => {
  const token = localStorage.apiToken;
  if (!token) {
    console.log("Not authenticated");
    return false;
  }

  try {
    let apiRequest = await axios.get(baseURI + "/stopVideoFeed/" + nodeId, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    const result = apiRequest.data;

    return result;
  } catch (error) {
    console.log(error);
    return false;
  }
};

const isDeviceStreamingVideo = async (nodeId) => {
  const token = localStorage.apiToken;
  if (!token) {
    console.log("Not authenticated");
    return false;
  }

  try {
    let apiRequest = await axios.get(baseURI + "/videoFeed/" + nodeId, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    const result = apiRequest.data;

    return result.data ? true : false;
  } catch (error) {
    console.log(error);
    return false;
  }
};

module.exports = {
  login,
  getNodes,
  getNode,
  changeLedColor,
  startVideoFeed,
  stopVideoFeed,
  isDeviceStreamingVideo,
};
