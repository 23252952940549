<template>
  <footer>
    <section>
      <span>Copyright &copy; 2021 SharkTrac. All rights reserved.</span>
    </section>
    <section>
      <a href="//rapidprototypesllc.com" class="img-wrapper">
        <img src="@/assets/images/rapid-logo.webp" alt="Rapid Prototypes LLC" />
      </a>
    </section>
  </footer>
</template>

<style lang="scss" scoped>
footer {
  margin-top: auto;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 10px 20px;
  background-color: rgba(#2a2a2a, 0.5);
  width: 100%;

  section:nth-of-type(1) {
    color: #ccc;
  }

  section:nth-of-type(2) {
    .img-wrapper {
      text-decoration: none;
      img {
        width: 58px;
        height: 60px;
        display: block;
      }
    }
  }
}
</style>

<script>
export default {
  name: "FooterPage",
};
</script>
